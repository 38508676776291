import React from 'react';

import Heading from '../../Heading';
import RugVariationsOverviewSectionIntro from './RugVariationsOverviewSections/RugVariationsOverviewSectionIntro';
import SearchSectionRugs from '../../Search/SearchSections/SearchSectionRugs';
import Section from '../../Heading/Section';

// Types
import { ReactNode } from 'react';

type Props = {
  appInfo: ReactNode;
  appInfoPortrait: ReactNode;
  carousel: ReactNode;
  footer: ReactNode;
  name: string;
  rugs: ReactNode;
  term: string;
  title?: string;
  titleHeaderPortrait: ReactNode;
};

const RugVariationsOverview = ({ appInfo, appInfoPortrait, carousel, footer, name, rugs, term, title, titleHeaderPortrait }: Props) => {
  const rugCount = React.Children.count(rugs);

  return (
    <>
      {!!title && <Heading isVisuallyHidden={true}>{title}</Heading>}

      <Section hasNoTag={true}>
        <Heading isVisuallyHidden={true}>{name} Variations</Heading>

        <RugVariationsOverviewSectionIntro appInfo={appInfo} name={name} titleHeaderPortrait={titleHeaderPortrait} rugCount={rugCount} />

        <SearchSectionRugs isLoading={false} resultsCount={rugCount} rugs={rugs} term={term} shouldRenderAllIfNoTerm={true} />

        {appInfoPortrait}

        {carousel}

        {footer}
      </Section>
    </>
  );
};

export default RugVariationsOverview;
