import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import SectionFooterShowrooms from '../../../components/Section/SectionFooterShowrooms';
import SectionFooterShowroomsShowroomContainer from '../SectionFooterShowrooms/SectionFooterShowroomsShowroom';
import SectionItemFooterSocialContainer from '../SectionItems/SectionItemFooterSocial';
import filterOutExclusivePartners from '../../../utils/showroom/filterOutExclusivePartners';
import notNullOrUndefined from '../../../utils/misc/notNullOrUndefined';

// Types
import { SectionFooterShowroomsDefaultContainerQuery } from './__generated__/SectionFooterShowroomsDefaultContainerQuery';

type Props = {
  backgroundColor: string;
  textColor: string;
};

const SectionFooterShowroomsDefaultContainer = ({ backgroundColor, textColor }: Props) => {
  const data = useStaticQuery<SectionFooterShowroomsDefaultContainerQuery>(graphql`
    query SectionFooterShowroomsDefaultContainerQuery {
      cms {
        showrooms(sort: "order") {
          ...SectionFooterShowroomsShowroomContainerFragment
          id
          type
        }
      }
    }
  `);

  if (!data.cms.showrooms) {
    throw Error('Something went wrong.');
  }

  const showrooms = data.cms.showrooms
    .filter(notNullOrUndefined)
    .filter(filterOutExclusivePartners)
    .map(showroom => <SectionFooterShowroomsShowroomContainer key={showroom.id} showroom={showroom} />);

  const social = <SectionItemFooterSocialContainer textColor={textColor} />;

  return (
    <SectionFooterShowrooms
      backgroundColor={backgroundColor}
      backgroundImage={null}
      backgroundPattern={null}
      isHiddenOnMobile={false}
      showrooms={showrooms}
      social={social}
      textColor={textColor}
    />
  );
};

export default SectionFooterShowroomsDefaultContainer;
