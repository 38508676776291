import styled from '../../../../utils/styling/styled';

import AppStoreIconAlt from '../../../SVG/AppStoreIconAlt';
import AppLogo from '../../../SVG/AppLogo';
import styleUtils from '../../../../utils/styling/styleUtils';

export const StyledAppLogo = styled(AppLogo)`
  ${styleUtils.margin.horizontal.rightHalf};

  border-radius: 0.5rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 2px 2px 2px 2px rgba(0, 0, 0, 0.06);
  fill: ${props => props.theme.color.white};

  width: 2.5rem;

  ${styleUtils.media.tablet.small} {
    width: 3rem;
  }
`;

export const AppStoreButton = styled(AppStoreIconAlt)`
  ${styleUtils.animation.appStoreAltButtonHover};

  height: 2.5rem;

  ${styleUtils.media.tablet.small} {
    height: 3rem;
  }
`;

export const AppStoreDownloadButton = styled(AppStoreIconAlt)`
  ${styleUtils.animation.appStoreAltButtonHover};
  height: 2.5rem;
  ${styleUtils.media.tablet.small} {
    height: 3rem;
  }
`;

export const IconsWrapper = styled.div`
  ${styleUtils.padding.vertical.topHalf};

  /* Flex Container */
  align-items: center;
  display: flex;
  flex-direction: row;

  ${styleUtils.media.tablet.small} {
    ${styleUtils.padding.vertical.topNone};
  }
`;

export const TeaserWrapper = styled.div`
  ${styleUtils.padding.vertical.topHalf};
`;

export const TeaserStrong = styled.p`
  color: ${props => props.theme.color.black};
`;

export const Teaser = styled.p`
  color: ${props => props.theme.color.black70};
`;

type WrapperProps = {
  isPortraitVariant?: boolean;
};

export const Wrapper = styled.aside<WrapperProps>`
  display: none;

  ${styleUtils.media.touchDevice} {
    background-color: ${props => props.theme.color.white};

    ${styleUtils.media.orientation.landscape} {
      display: ${props => (!props.isPortraitVariant ? 'block' : 'none')};
      width: 90%;
    }

    ${styleUtils.media.orientation.portrait} {
      ${styleUtils.padding.horizontal.both};
      ${styleUtils.padding.vertical.bothHalf};

      display: ${props => (props.isPortraitVariant ? 'block' : 'none')};
      margin-top: 1rem;
      width: 100%;
    }
  }
`;
