import React from 'react';

const SCROLL_DELAY = 500;

/**
 * We scroll after a short delay to give a better sense of on what page the user is that they're are other rugs as well.
 */
const useScrollIntoView = <T extends HTMLElement>(shouldScrollTo: boolean) => {
  const ref = React.useRef<T>(null);

  React.useEffect(() => {
    if (typeof window !== 'undefined' && !!ref.current) {
      if (shouldScrollTo) {
        setTimeout(() => {
          if (!!ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
          }
        }, SCROLL_DELAY);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return ref;
};

export default useScrollIntoView;
