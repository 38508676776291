import routes from '../../config/routes';
import buildRugSlug from './buildRugSlug';

type BuildRugDetailUrlInput = {
  collectionSlug: string;
  rugName?: string;
  rugId: number | string;
};

const buildRugDetailUrl = ({ collectionSlug, rugName, rugId }: BuildRugDetailUrlInput) => {
  const slug = !!rugName ? buildRugSlug(rugName) : null;

  return `${routes.collection}/${collectionSlug}/overview/detail/${rugId}${!!slug ? `-${slug}` : ''}`;
};

export default buildRugDetailUrl;
