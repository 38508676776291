import React from 'react';

import Link from '../../../../Link';
import MediaImageFluid from '../../../../MediaImage/MediaImageFluid';
import RugNewBadge from '../../../../Rug/RugItems/RugNewBadge';
import VisuallyHiddenSpan from '../../../../VisuallyHiddenSpan';

import * as S from './styles';

// Types
import { CMS_ENUM_RUG_AWARD } from '../../../../../../types/global';
import { FluidImage } from '../../../../MediaImage/GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';

type Props = {
  award?: CMS_ENUM_RUG_AWARD | null;
  caption?: string | null;
  color: string | null;
  extraTitle?: string | null;
  isCritical: boolean;
  isNew: boolean;
  material: string | null;
  name: string;
  picture: FluidImage;
  pinterestUrl: string | null;
  url: string;
};

const SearchSectionRugsRug = React.forwardRef<HTMLDivElement, Props>(
  ({ award, caption, color, extraTitle, isCritical, isNew, material, name, picture, /* pinterestUrl, */ url }: Props, ref) => {
    return (
      <S.Wrapper ref={ref}>
        <S.PictureLink url={url}>
          <VisuallyHiddenSpan>Link to the {name} detail page</VisuallyHiddenSpan>

          <MediaImageFluid
            alt={`Picture of a ${name} rug`}
            caption={caption}
            fadeIn={!isCritical}
            loading={isCritical ? 'eager' : undefined}
            image={picture}
            vh={60}
          />
        </S.PictureLink>

        <S.Award award={award} />

        {isNew && <RugNewBadge />}

        <S.ContentWrapper>
          <S.MetaWrapper>
            <S.MetaListExtraTitle>{!!extraTitle && <S.MetaListItem>{extraTitle}</S.MetaListItem>}</S.MetaListExtraTitle>

            <Link url={url}>
              <VisuallyHiddenSpan>Link to the</VisuallyHiddenSpan>
              <S.Name>{name}</S.Name>
              <VisuallyHiddenSpan>detail page</VisuallyHiddenSpan>
            </Link>

            <S.MetaList>
              {!!color && (
                <S.MetaListItem>
                  <VisuallyHiddenSpan>Color: </VisuallyHiddenSpan> {color}
                </S.MetaListItem>
              )}

              {!!material && (
                <S.MetaListItem>
                  <VisuallyHiddenSpan>Material: </VisuallyHiddenSpan> {material}
                </S.MetaListItem>
              )}
            </S.MetaList>
          </S.MetaWrapper>
        </S.ContentWrapper>
      </S.Wrapper>
    );
  },
);

export default SearchSectionRugsRug;
