import React from 'react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import Heading from '../../../Heading';

import * as S from './styles';

// Types
import { ReactNode } from 'react';

type Props = {
  isLoading: boolean;
  resultsCount: number;
  rugs: ReactNode;
  shouldRenderAllIfNoTerm?: boolean;
  term: string | null;
};

const SearchSectionRugs = ({ isLoading, resultsCount, rugs, shouldRenderAllIfNoTerm, term }: Props) => {
  const renderIsLoading = () => {
    if (!isLoading) {
      return null;
    }

    return (
      <S.InfoWrapper>
        <S.InfoText>Loading search results.</S.InfoText> <S.Spinner icon={faSpinner} size="4x" pulse={true} />
      </S.InfoWrapper>
    );
  };

  const renderNoResults = () => {
    if (isLoading || resultsCount !== 0 || !term) {
      return null;
    }

    return (
      <S.InfoWrapper>
        <S.InfoText>
          No results for &quot;{term || ''}&quot;,
          <br /> please refine or change your search.
        </S.InfoText>
      </S.InfoWrapper>
    );
  };

  const renderNoTerm = () => {
    if (isLoading || !!term || shouldRenderAllIfNoTerm) {
      return null;
    }

    return (
      <S.InfoWrapper>
        <S.InfoText>Please enter a search term.</S.InfoText>
      </S.InfoWrapper>
    );
  };

  return (
    <>
      <Heading isVisuallyHidden={true}>Search Results</Heading>

      <S.Wrapper>
        {renderIsLoading()}
        {renderNoResults()}
        {renderNoTerm()}

        {!isLoading && resultsCount > 0 && (!!term || shouldRenderAllIfNoTerm) && rugs}
      </S.Wrapper>
    </>
  );
};

export default SearchSectionRugs;
