import notNullOrUndefined from '../../../../../utils/misc/notNullOrUndefined';

type GetExtraTitleRugInput = {
  isMotherpiece?: boolean | null;
  postExtra?: string | null;
  preExtra?: string | null;
};

export const getExtraTitle = (rug: GetExtraTitleRugInput) => {
  if (rug.isMotherpiece) {
    return 'Motherpiece';
  }

  if (!rug.postExtra && !rug.preExtra) {
    return null;
  }

  if (rug.postExtra === 'Polonaise Redbridge') {
    return 'Spectrum Surpreme';
  }

  if (rug.preExtra === 'Frame') {
    return 'Mauro Spectrum Surpreme';
  }

  if (notNullOrUndefined(rug.postExtra)) {
    if (rug.postExtra.includes('Basic')) {
      return 'Spectrum Basics';
    }
  }

  return null;
};
