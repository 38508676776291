import React from 'react';

import theme from '../../../utils/styling/theme';

// Types
import { SVGAttributes } from 'react';

type Props = {} & SVGAttributes<SVGElement>;

const AppLogo = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 162.494 161.811" {...props}>
      <rect fill={theme.color.brand} height="121.26" width="121.89" x="20.3" y="20.35" />

      <path
        d="M61.49,41.14,56.15,60.22H67.39L62.05,41.14Zm8.43,46-5.33,19.07H75.82L70.49,87.14ZM0,0V161.81H162.49V0ZM116.2,49.26h17.73V67H116.2ZM25,71l3.08-2a6.66,6.66,0,0,0,5.54,3.28c2.51,0,4.51-1.23,4.51-5.49V38.26h4.11V65.75c0,7-3,10.06-8.52,10.06C29.43,75.81,27,73.55,25,71Zm23.67,50.25-11-19.69-6,7.28v12.41h-4.1v-37h4.1V103.5h.1L47.1,84.26H52L40.48,98.21l12.88,23.08Zm3-46H47.33l11.39-37h6.1l11.39,37H71.9L68.41,63.7H55.13Zm28.69,46L76.85,109.7H63.56l-3.48,11.59H55.77l11.38-37h6.11l11.38,37Zm27.21-33.54H96.46v33.54h-4.1V87.75H81.28V84.26h26.26Zm.1-12.45h-3.38l-18.77-33h-.11v33H81.69v-37h5.9l16.15,28.52H104V38.26h3.69v37Zm30,46h-4.11V103.65H115.75v17.64h-4.11v-37h4.11v15.9h17.74V84.26h4.11Z"
        fill={theme.color.white}
      />
    </svg>
  );
};

export default AppLogo;
