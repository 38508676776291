import notNullOrUndefined from '../misc/notNullOrUndefined';

type BuildRugColorInput = {
  color: string | null;
  searchColor1: string | null;
  searchColor2: string | null;
  searchColor3: string | null;
};

const buildRugColor = ({ color, searchColor1, searchColor2, searchColor3 }: BuildRugColorInput) => {
  if (notNullOrUndefined(color) && color.trim().length > 0) {
    return color.trim();
  }

  return [searchColor1, searchColor2, searchColor3]
    .filter(notNullOrUndefined)
    .map(x => x.trim())
    .join(' / ');
};

export default buildRugColor;
