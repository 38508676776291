import idx from 'idx';
import { graphql } from 'gatsby';

import buildRugName from '../rug/buildRugName';
import buildRugVariationsPageUrl from '../url/buildRugVariationsPageUrl';
import getSeoPicture from './getSeoPicture';

// Types
import { BuildVariationsPageSeoPropsFragment } from './__generated__/BuildVariationsPageSeoPropsFragment';

export const fragment = graphql`
  fragment BuildVariationsPageSeoPropsFragment on CMS_Rug {
    extra
    id
    name
    collection_jk {
      collectionPage {
        slug
      }
    }
    picture {
      ...SeoMetaPictureFragment
    }
  }
`;

const buildRugVariationsPageSeoProps = (rug: BuildVariationsPageSeoPropsFragment) => {
  const collectionSlug = idx(rug, _ => _.collection_jk.collectionPage.slug);

  if (!collectionSlug) {
    return {};
  }

  const name = `${buildRugName({ extra: rug.extra, name: rug.name })} Variations`;
  const picture = idx(rug, _ => _.picture) || null;

  const canonicalUrl = buildRugVariationsPageUrl({ id: rug.id, slug: collectionSlug });
  const image = getSeoPicture({ alt: name, picture });

  return {
    canonicalUrl,
    image,
    openGraphTitle: name,
    openGraphType: 'product',
    title: name,
  };
};

export default buildRugVariationsPageSeoProps;
