import React from 'react';
import idx from 'idx';
import { graphql } from 'gatsby';

import SearchSectionRugsRug from '../../../../../components/Search/SearchSections/SearchSectionRugs/SearchSectionRugsRug';
import buildPinterestRugShareUrl from '../../../../../utils/url/buildPinterestRugShareUrl';
import buildRugColor from '../../../../../utils/rug/buildRugColor';
import buildRugDetailUrl from '../../../../../utils/url/buildRugDetailUrl';
import buildRugMaterial from '../../../../../utils/rug/buildRugMaterial';
import buildRugName from '../../../../../utils/rug/buildRugName';
import getPictureShareUrl from '../../../../../utils/image/getPictureShareUrl';
import isRugNew from '../../../../../utils/rug/isRugNew';
import useScrollIntoView from '../../../../../utils/hooks/useScrollIntoView';

import { getExtraTitle } from './utils';

// Types
import { SearchSectionRugsRugContainerFragment } from './__generated__/SearchSectionRugsRugContainerFragment';

export const fragment = graphql`
  fragment SearchSectionRugsRugContainerFragment on CMS_Rug {
    award
    color
    created_at
    extra
    id
    isMotherpiece
    isPrimary
    material1
    material2
    material3
    name
    postExtra
    preExtra
    searchColor1
    searchColor2
    searchColor3
    picture {
      ...OverviewRugPictureFragment
    }
  }
`;

type Props = {
  isCritical: boolean;
  rug: SearchSectionRugsRugContainerFragment;
  shouldScrollTo?: boolean;
  slug?: string | null;
};

const SearchSectionRugsRugContainer = ({ isCritical, rug, shouldScrollTo = false, slug }: Props) => {
  const ref = useScrollIntoView<HTMLDivElement>(shouldScrollTo);

  const picture = idx(rug, _ => _.picture.file.childImageSharp.fluid);

  if (!picture || !slug) {
    return null;
  }

  const isNew = isRugNew(rug.created_at);
  const color = buildRugColor(rug);
  const material = buildRugMaterial(rug);
  const pictureShareUrl = getPictureShareUrl(picture);
  const pinterestUrl = buildPinterestRugShareUrl({ collectionSlug: slug, rugId: rug.id, pictureShareUrl, rugName: rug.name });
  const rugDetailUrl = buildRugDetailUrl({ collectionSlug: slug, rugId: rug.id });
  const rugExtraTitle = getExtraTitle(rug);
  const rugName = buildRugName({ extra: rug.extra, name: rug.name, postExtra: rug.postExtra, preExtra: rug.preExtra });

  return (
    <SearchSectionRugsRug
      award={rug.award}
      color={color}
      extraTitle={rugExtraTitle}
      isCritical={isCritical || shouldScrollTo}
      isNew={isNew}
      material={material}
      name={rugName}
      picture={picture}
      pinterestUrl={pinterestUrl}
      ref={ref}
      url={rugDetailUrl}
    />
  );
};

export default SearchSectionRugsRugContainer;
