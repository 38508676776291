import styled from '../../../../utils/styling/styled';

export const Button = styled.div`
  /* Flex Container */
  align-items: flex-end;
  display: flex;
  flex-direction: row;

  background-color: ${props => props.theme.color.brand};
  height: 3rem;
  left: 1.5rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
  position: absolute;
  top: 1.5rem;
  transition: background-color 300ms ease-in-out;
  width: 3rem;
`;

export const Text = styled.span`
  color: ${props => props.theme.color.white};
  font-size: 0.9rem;
  text-transform: uppercase;
`;
