import routes from '../../config/routes';

type BuildRugVariationsPageUrl = {
  id: string;
  slug: string;
};

const buildRugVariationsPageUrl = ({ id, slug }: BuildRugVariationsPageUrl) => {
  return `${routes.collection}/${slug}/overview/detail/${id}/variations`;
};

export default buildRugVariationsPageUrl;
