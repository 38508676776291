import React from 'react';

import VisuallyHiddenSpan from '../../../VisuallyHiddenSpan';

import * as S from './styles';

type Props = {};

const RugNewBadge = (_props: Props) => {
  return (
    <S.Button>
      <S.Text>New</S.Text>

      <VisuallyHiddenSpan>Rug</VisuallyHiddenSpan>
    </S.Button>
  );
};

export default RugNewBadge;
