import styled from '../../../utils/styling/styled';

import ScrollSection from '../../ScrollSection';
import styleUtils from '../../../utils/styling/styleUtils';

export const ChildrenWrapper = styled.div`
  ${styleUtils.media.orientation.portrait} {
    width: 100%;

    ${styleUtils.media.tablet.small} {
      width: 50%;
    }
  }
`;

type ContentProps = {
  textColor: string;
};

export const Content = styled.div<ContentProps>`
  /* Flex Container */
  display: flex;
  justify-content: space-between;

  color: ${props => props.textColor};

  ${styleUtils.media.orientation.landscape} {
    ${styleUtils.margin.vertical.topDouble};
    ${styleUtils.margin.vertical.bottomDouble};

    /* Flex Container */
    flex-direction: column;

    height: 100%;
    width: 90%;
  }

  ${styleUtils.media.orientation.portrait} {
    /* Flex Container */
    flex-direction: column;

    margin-right: 1rem;

    ${styleUtils.media.tablet.small} {
      /* Flex Container */
      flex-direction: row;

      margin-right: 0;
    }
  }
`;

export const TitleHeaderPortraitWrapper = styled.div`
  ${styleUtils.media.orientation.landscape} {
    display: none;
  }
`;

type SectionProps = {
  backgroundColor: string | null;
};

export const Section = styled(ScrollSection)<SectionProps>`
  /* Flex Container */
  flex-direction: column;

  background-color: ${props => props.theme.color.white};

  padding-top: calc(4rem + 90px);

  ${styleUtils.media.desktop.large} {
    padding-top: calc(4rem + 120px);
  }

  ${styleUtils.media.orientation.landscape} {
    ${styleUtils.padding.horizontal.leftQuadruple};

    flex: 0 0 35vw;
    min-width: 35vw;
    width: 35vw;
  }

  ${styleUtils.media.orientation.portrait} {
    ${styleUtils.padding.horizontal.both};
    ${styleUtils.padding.vertical.bottom};

    /* height: 100%; */
    min-height: auto;
  }
`;
