import React from 'react';
import idx from 'idx';
import { graphql } from 'gatsby';

import CollectionOverviewPageBreadcrumb from '../../../utils/seo/CollectionOverviewPageBreadcrumb';
import RugVariationsOverview from '../../../components/Rug/RugVariationsOverview';
import SEOContainer from '../../Layout/SEO';
import SearchSectionRugsRugContainer from '../../Search/SearchSections/SearchSectionRugs/SearchSectionRugsRug';
import SectionCarouselCollectionsContainer from '../../Section/SectionCarouselCollections';
import SectionFooterShowroomsDefaultContainer from '../../Section/SectionFooterShowroomsDefault';
import SectionOverviewIntroAppInfoContainer from '../../Section/SectionOverviewIntro/SectionOverviewIntroAppInfo';
import TitleHeaderPortraitContainer from '../../TitleHeader/TitleHeaderPortrait';
import buildCollectionOverviewUrl from '../../../utils/url/buildCollectionOverviewUrl';
import buildRugName from '../../../utils/rug/buildRugName';
import buildRugVariationsPageSeoProps from '../../../utils/seo/buildRugVariationsPageSeoProps';
import notNullOrUndefined from '../../../utils/misc/notNullOrUndefined';
import theme from '../../../utils/styling/theme';
import useHeaderContext from '../../../utils/context/HeaderContext';

import { shouldScrollTo, sortNewRugsToFront } from './utils';

// Types
import {
  RugMotherpiecePageContainerFragment,
  RugMotherpiecePageContainerFragment_collection_jk_collectionPage,
} from './__generated__/RugMotherpiecePageContainerFragment';
import buildRugDetailUrl from '../../../utils/url/buildRugDetailUrl';

type Props = {
  isInternalLink: boolean;
  rug: RugMotherpiecePageContainerFragment;
};

export const fragment = graphql`
  fragment RugMotherpiecePageContainerFragment on CMS_Rug {
    ...BuildVariationsPageSeoPropsFragment
    ...SearchSectionRugsRugContainerFragment
    id
    isPrimary
    color
    isVisibleOnWebsite
    collection_jk {
      name
      collectionPage {
        ...CollectionOverviewPageBreadcrumbFragment
        slug
      }
    }
    secondaryRugs {
      ...SearchSectionRugsRugContainerFragment
      id
      isPrimary
      isVisibleOnWebsite
      collection_jk {
        name
        collectionPage {
          ...BuildCollectionOverviewSeoPropsFragment
          ...CollectionOverviewPageBreadcrumbFragment
          slug
        }
      }
    }
  }
`;

const RugMotherpiecePageContainer = ({ isInternalLink, rug }: Props) => {
  const collectionPage = idx(rug, _ => _.collection_jk.collectionPage) as RugMotherpiecePageContainerFragment_collection_jk_collectionPage;

  const seoProps = buildRugVariationsPageSeoProps(rug);

  const collectionName = idx(rug, _ => _.collection_jk.name);
  const collectionSlug = idx(collectionPage, _ => _.slug);
  const rugName = buildRugName(rug);
  const secondaryRugs = (rug.secondaryRugs || []).filter(notNullOrUndefined);

  const rugs = [rug, ...secondaryRugs];

  const sortedRugs = sortNewRugsToFront(rugs);

  const { setHeader } = useHeaderContext();

  React.useEffect(() => {
    if (!!collectionName && !!collectionSlug) {
      const nextBackButtonTitle = isInternalLink ? 'Back to Rug Detail Page' : 'Back to collection overview page';
      const nextBackButtonUrl = isInternalLink
        ? buildRugDetailUrl({ collectionSlug, rugId: rug.id })
        : buildCollectionOverviewUrl(collectionSlug);

      setHeader({
        backButtonTitle: nextBackButtonTitle,
        backButtonUrl: nextBackButtonUrl,
        title: rugName,
        subtitle: 'Variations',
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionName, collectionSlug]);

  if (!collectionName || !collectionSlug) {
    return null;
  }

  const appInfo = <SectionOverviewIntroAppInfoContainer />;
  const appInfoPortrait = <SectionOverviewIntroAppInfoContainer isPortraitVariant={true} />;
  const carousel = <SectionCarouselCollectionsContainer isHiddenOnMobile={false} />;
  const footer = <SectionFooterShowroomsDefaultContainer backgroundColor={theme.color.white} textColor={theme.color.black} />;

  const titleHeaderPortrait = <TitleHeaderPortraitContainer />;

  const mappedRugs = sortedRugs.map((rugItem, index) => (
    <SearchSectionRugsRugContainer
      isCritical={index <= 4}
      key={rugItem.id}
      rug={rugItem}
      shouldScrollTo={shouldScrollTo(rugItem)}
      slug={collectionPage.slug}
    />
  ));

  return (
    <>
      {/* SEO */}
      <CollectionOverviewPageBreadcrumb collectionPage={collectionPage} />
      <SEOContainer {...seoProps} />

      <RugVariationsOverview
        appInfo={appInfo}
        appInfoPortrait={appInfoPortrait}
        carousel={carousel}
        footer={footer}
        name={rugName}
        rugs={mappedRugs}
        term="term"
        title={seoProps.title}
        titleHeaderPortrait={titleHeaderPortrait}
      />
    </>
  );
};

export default RugMotherpiecePageContainer;
