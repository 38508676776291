import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styled from '../../../../utils/styling/styled';

import Section from '../../../Heading/Section';
import styleUtils from '../../../../utils/styling/styleUtils';

export const Spinner = styled(FontAwesomeIcon)`
  ${styleUtils.margin.vertical.top};

  ${styleUtils.media.desktop.small} {
    ${styleUtils.margin.vertical.topQuadruple};
  }
`;

export const InfoText = styled.span`
  ${styleUtils.font.weight.bold};

  font-size: 2rem;
  text-transform: uppercase;
  width: 100%;

  ${styleUtils.media.orientation.landscape} {
    text-align: start;
  }

  ${styleUtils.media.orientation.portrait} {
    text-align: center;
  }
`;

export const InfoWrapper = styled.div`
  ${styleUtils.padding.horizontal.bothHalf};
  ${styleUtils.padding.vertical.bothHalf};

  /* Flex Container */
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  min-height: 20vh;
  min-width: 50vw;
  width: 100%;
`;

export const Wrapper = styled(Section)`
  /* Flex Container */
  align-items: flex-start;
  display: flex;

  background-color: ${props => props.theme.color.white};

  ${styleUtils.media.orientation.landscape} {
    /* Flex Container */
    flex-direction: row;
    flex: none;

    margin-left: -1px;
    padding-top: calc(4rem + 90px);

    ${styleUtils.media.desktop.large} {
      padding-top: calc(4rem + 120px);
    }
  }

  ${styleUtils.media.orientation.portrait} {
    /* Flex Container */
    align-items: center;
    flex-direction: column;
    flex: none;

    margin-top: -1px;
  }
`;
