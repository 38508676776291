import React from 'react';
import idx from 'idx';
import { Generic, JSONLD } from 'react-structured-data';
import { graphql } from 'gatsby';

import buildBreadcrumbListItem from './buildBreadcrumbListItem';
import buildCollectionOverviewTitle from './buildCollectionOverviewTitle';
import buildCollectionOverviewUrl from '../url/buildCollectionOverviewUrl';
import buildCollectionPageUrl from '../url/buildCollectionPageUrl';
import { STRUCTURED_DATA_HOMEPAGE_BREADCRUMB_ITEM } from './constants';

// Types
import { CollectionOverviewPageBreadcrumbFragment } from './__generated__/CollectionOverviewPageBreadcrumbFragment';

export const fragment = graphql`
  fragment CollectionOverviewPageBreadcrumbFragment on CMS_Collectionpage {
    slug
    title
    collection_jk {
      name
    }
  }
`;

type Props = {
  collectionPage: CollectionOverviewPageBreadcrumbFragment;
};

const CollectionOverviewPageBreadcrumb = ({ collectionPage }: Props) => {
  const collectionName = idx(collectionPage, _ => _.collection_jk.name);
  const collectionPageTitle = collectionPage.title;
  const collectionSlug = collectionPage.slug;

  if (!collectionName || !collectionPageTitle || !collectionSlug) {
    return null;
  }

  const collectionOverviewPageUrl = buildCollectionOverviewUrl(collectionSlug);
  const collectionOverviewTitle = buildCollectionOverviewTitle(collectionName);
  const collectionPageUrl = buildCollectionPageUrl(collectionSlug);

  const collectionListItem = buildBreadcrumbListItem({ name: collectionPageTitle, position: 2, url: collectionPageUrl });
  const collectionOverviewListItem = buildBreadcrumbListItem({
    name: collectionOverviewTitle,
    position: 3,
    url: collectionOverviewPageUrl,
  });

  const schema = {
    itemListElement: [STRUCTURED_DATA_HOMEPAGE_BREADCRUMB_ITEM, collectionListItem, collectionOverviewListItem],
  };

  return (
    <JSONLD dangerouslyExposeHtml={true}>
      <Generic jsonldtype="BreadcrumbList" schema={schema} />
    </JSONLD>
  );
};

export default CollectionOverviewPageBreadcrumb;
