import React from 'react';
import idx from 'idx';
import { graphql, useStaticQuery } from 'gatsby';

import SectionOverviewIntroAppInfo from '../../../../components/Section/SectionOverviewIntro/SectionOverviewIntroAppInfo';

// Types
import { SectionOverviewIntroAppInfoContainerQuery } from './__generated__/SectionOverviewIntroAppInfoContainerQuery';

type Props = {
  isPortraitVariant?: boolean;
};

const SectionOverviewIntroAppInfoContainer = ({ isPortraitVariant }: Props) => {
  const data = useStaticQuery<SectionOverviewIntroAppInfoContainerQuery>(graphql`
    query SectionOverviewIntroAppInfoContainerQuery {
      site {
        siteMetadata {
          socialMedia {
            appStore
          }
        }
      }
    }
  `);

  const appStoreUrl = idx(data, _ => _.site.siteMetadata.socialMedia.appStore) || null;

  return <SectionOverviewIntroAppInfo appStoreUrl={appStoreUrl} isPortraitVariant={isPortraitVariant} />;
};

export default SectionOverviewIntroAppInfoContainer;
