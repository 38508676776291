import React from 'react';

import TitleHeader from '../../../components/TitleHeader';
import useHeaderContext from '../../../utils/context/HeaderContext';

// Types

type Props = {};

const TitleHeaderPortraitContainer = (_props: Props) => {
  const { backButtonTitle, backButtonUrl, isTitleHeaderVisible, subtitle, title } = useHeaderContext();

  if (!isTitleHeaderVisible) {
    return null;
  }

  return <TitleHeader backButtonTitle={backButtonTitle} headerTitle={title} subtitle={subtitle} url={backButtonUrl} />;
};

export default TitleHeaderPortraitContainer;
