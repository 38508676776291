import React from 'react';

import Link from '../../../Link';

import * as S from './styles';

// Types

type Props = {
  appStoreUrl: string | null;
  isPortraitVariant?: boolean;
};

const SectionOverviewIntroAppInfo = ({ appStoreUrl, isPortraitVariant }: Props) => {
  if (!appStoreUrl) {
    return null;
  }

  return (
    <S.Wrapper aria-hidden={!isPortraitVariant} isPortraitVariant={isPortraitVariant}>
      <S.IconsWrapper>
        <S.StyledAppLogo />

        <Link url={appStoreUrl}>
          <S.AppStoreDownloadButton />
        </Link>
      </S.IconsWrapper>

      <S.TeaserWrapper>
        <S.TeaserStrong>Transform your smartphone and tablet into a virtual room.</S.TeaserStrong>

        <S.Teaser>Just snap a picture of your space and place your favorite carpet in the picture.</S.Teaser>

        <S.Teaser>Browse, save and share hundreds of spectacular Jan Kath rugs of inspiring your interior designs.</S.Teaser>
      </S.TeaserWrapper>
    </S.Wrapper>
  );
};

export default SectionOverviewIntroAppInfo;
