import React from 'react';

import theme from '../../../utils/styling/theme';

import * as S from './styles';

// Types
import { ReactNode } from 'react';

type Props = {
  appInfo: ReactNode;
  children: ReactNode;
  className?: string;
  titleHeaderPortrait: ReactNode;
};

const SectionOverviewIntro = ({ appInfo, children, className, titleHeaderPortrait }: Props) => {
  return (
    <S.Section
      backgroundColor={theme.color.white}
      backgroundImage={null}
      contentPositionY="center"
      className={className}
      isHiddenOnMobile={false}
      isNotClipable={true}
      shouldNotAutoHeight={true}
      shouldNotMakeWiderIfNeighborIsClipped={true}
    >
      <S.TitleHeaderPortraitWrapper>{titleHeaderPortrait}</S.TitleHeaderPortraitWrapper>

      <S.Content textColor={theme.color.black}>
        <S.ChildrenWrapper>{children}</S.ChildrenWrapper>

        {appInfo}
      </S.Content>
    </S.Section>
  );
};

export default SectionOverviewIntro;
