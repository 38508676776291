import idx from 'idx';

import envConfig from '../../config/env';

// Types
import { FluidImage } from '../../components/MediaImage/GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';

const getPictureShareUrl = (picture: FluidImage) => {
  const src = idx(picture, _ => _.src);

  if (!src) {
    return null;
  }

  const pictureShareUrl = `${envConfig.appUrl}${src}`;

  return pictureShareUrl;
};

export default getPictureShareUrl;
