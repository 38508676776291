import idx from 'idx';

import buildAbsoluteUrl from './buildAbsoluteUrl';

type GetSeoPictureInput = {
  alt?: string | null;
  picture?: {
    file?: {
      childImageSharp?: {
        fixed?: {
          height?: number | null;
          src?: string | null;
          width?: number | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

const getSeoPicture = ({ alt, picture }: GetSeoPictureInput) => {
  const height = idx(picture, _ => _.file.childImageSharp.fixed.height) || null;
  const src = idx(picture, _ => _.file.childImageSharp.fixed.src);
  const width = idx(picture, _ => _.file.childImageSharp.fixed.width) || null;

  if (typeof src !== 'string') {
    return null;
  }

  const url = buildAbsoluteUrl(src);

  return { alt, height, url, width };
};

export default getSeoPicture;
