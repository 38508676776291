import notNullOrUndefined from '../misc/notNullOrUndefined';

type BuildRugMaterialInput = {
  material1: string | null;
  material2: string | null;
  material3: string | null;
};

const buildRugMaterial = ({ material1, material2, material3 }: BuildRugMaterialInput) => {
  return [material1, material2, material3].filter(notNullOrUndefined).join(' / ');
};

export default buildRugMaterial;
