import styled from '../../../../../utils/styling/styled';

import Heading from '../../../../Heading';
import Link from '../../../../Link';
import RugAward from '../../../../Rug/RugItems/RugAward';
import styleUtils from '../../../../../utils/styling/styleUtils';

export const Award = styled(RugAward)`
  position: absolute;
  z-index: 1;

  right: 0px;
  top: -30px;

  ${styleUtils.media.tablet.large} {
    top: -30px;
  }

  ${styleUtils.media.orientation.landscape} {
    right: -30px;

    ${styleUtils.media.desktop.huge} {
      top: -70px;
    }
  }

  ${styleUtils.media.orientation.portrait} {
    ${styleUtils.media.desktop.huge} {
      top: -50px;
    }
  }
`;

export const ContentWrapper = styled.div`
  ${styleUtils.margin.vertical.topHalf};
  ${styleUtils.padding.horizontal.bothHalf};

  /* Flex Container */
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
`;

export const MetaList = styled.ul`
  ${styleUtils.margin.vertical.topQuarter};
`;

export const MetaListExtraTitle = styled.ul``;

export const MetaListItem = styled.li`
  color: ${props => props.theme.color.black80};
  font-size: 0.9rem;
`;

export const MetaWrapper = styled.div`
  /* Flex Container */
  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const Name = styled(Heading)`
  color: ${props => props.theme.color.black};

  &:hover {
    color: ${props => props.theme.color.brand};
  }
`;

export const PictureLink = styled(Link)`
  width: 100%;

  ${styleUtils.media.tablet.small} {
    ${styleUtils.animation.scaleImageOnHover};
  }
`;

export const Wrapper = styled.div`
  /* Flex Container */
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;

  position: relative;

  ${styleUtils.media.orientation.portrait} {
    margin-bottom: 4rem;

    width: 90vw;

    ${styleUtils.media.tablet.small} {
      flex: 1 1 32%;
    }
  }

  ${styleUtils.media.orientation.landscape} {
    /* max-height: 65vh; */
    margin-right: 4rem;
    /* width: 100%; */
  }
`;
