import React from 'react';
import idx from 'idx';
import { graphql } from 'gatsby';

import LayoutContainer from '../containers/Layout';
import RugMotherpiecePageContainer from '../containers/Rug/RugMotherpiecePage';
import theme from '../utils/styling/theme';

// Types
import { RugMotherpiecePageTemplateQuery } from './__generated__/RugMotherpiecePageTemplateQuery';
import { PageRendererProps } from 'gatsby';

export const pageQuery = graphql`
  query RugMotherpiecePageTemplateQuery($where: CMS_JSON!) {
    cms {
      rugs(where: $where) {
        ...RugMotherpiecePageContainerFragment
        collection_jk {
          collectionPage {
            slug
          }
        }
        secondaryRugs {
          ...RugMotherpiecePageContainerFragment
        }
      }
    }
  }
`;

type Props = {
  data: RugMotherpiecePageTemplateQuery;
} & PageRendererProps;

const RugMotherpiecePageTemplate = ({ data, location }: Props) => {
  if (!data.cms.rugs || data.cms.rugs.length !== 1) {
    throw Error('Something went wrong');
  }

  const [rug] = data.cms.rugs;
  const collectionSlug = idx(rug, _ => _.collection_jk.collectionPage.slug);
  const isInternalLink = (idx(location, _ => _.state.isInternalLink) as boolean | undefined) || false;

  if (!rug || !collectionSlug) {
    throw Error('Something went wrong');
  }

  return (
    <LayoutContainer backgroundColor={theme.color.white} logoColor={theme.color.brand}>
      <RugMotherpiecePageContainer isInternalLink={isInternalLink} rug={rug} />
    </LayoutContainer>
  );
};

export default RugMotherpiecePageTemplate;
