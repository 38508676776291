import React from 'react';

import Link from '../../../../Link';
import SectionOverviewIntro from '../../../../Section/SectionOverviewIntro';
import routes from '../../../../../config/routes';

// Types
import { ReactNode } from 'react';

type Props = {
  appInfo: ReactNode;
  name: string;
  rugCount: number;
  titleHeaderPortrait: ReactNode;
};

const RugVariationsOverviewSectionIntro = ({ appInfo, name, rugCount, titleHeaderPortrait }: Props) => {
  return (
    <SectionOverviewIntro appInfo={appInfo} titleHeaderPortrait={titleHeaderPortrait}>
      {rugCount} variatons of <i>{name}</i> found
      <br />
      <br /> Every Jan Kath carpet can be individually designed in terms of size, format, and materials. Even the collections can be
      combined with each other using a kind of modular system.{' '}
      <Link style={{ textDecoration: 'underline' }} url={routes.globalDistribution}>
        Find your nearest showroom
      </Link>
      .
    </SectionOverviewIntro>
  );
};

export default RugVariationsOverviewSectionIntro;
