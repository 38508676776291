import idx from 'idx';
import { compareDesc, parseISO } from 'date-fns';
import { globalHistory } from '@reach/router';

import isRugNew from '../../../utils/rug/isRugNew';
import notNullOrUndefined from '../../../utils/misc/notNullOrUndefined';

// Types
import { CMS_ENUM_RUG_AWARD } from '../../../../types/global';
import { CollectionOverviewContainerFragment_collection_jk_rugs_picture } from '../../Collection/CollectionOverview/__generated__/CollectionOverviewContainerFragment';

type ShouldScrollToInput = {
  id: string;
};

export const shouldScrollTo = ({ id }: ShouldScrollToInput) => {
  return (globalHistory.location.hash || '').replace('#', '') === id;
};

export type SortableRug = {
  __typename: 'CMS_Rug';
  award: CMS_ENUM_RUG_AWARD | null;
  collectionSlug?: string | null;
  created_at: any;
  color: string | null;
  extra: string | null;
  id: string;
  isNew?: boolean;
  isMotherpiece: boolean | null;
  isPrimary: boolean | null;
  isVisibleOnWebsite: boolean | null;
  material1: string | null;
  material2: string | null;
  material3: string | null;
  name: string;
  picture: CollectionOverviewContainerFragment_collection_jk_rugs_picture | null;
  postExtra: string | null;
  preExtra: string | null;
  searchColor1: string | null;
  searchColor2: string | null;
  searchColor3: string | null;
  collection_jk: {
    collectionPage: {
      slug: string | null;
    } | null;
  } | null;
};

// Rugs that are created before `envConfig.rugIsNewDays` days ago, are considered old and are sorted by name.
// Rugs that are new, will be sorted by date from newest to oldest.
export const sortNewRugsToFront = (rugs: (SortableRug | null)[] | null) => {
  const rugsWithPreOrPostExtra = (rugs || []).filter(notNullOrUndefined).filter(rug => !!rug.preExtra || !!rug.postExtra);
  const rugsWithoutPreOrPostExtra = (rugs || []).filter(notNullOrUndefined).filter(rug => !rug.preExtra && !rug.postExtra);

  const filteredMappedRugs = rugsWithoutPreOrPostExtra
    .filter(rug => rug.isVisibleOnWebsite)
    // A bit hacky but where mixing proper strongly typed GraphQL rugs and rugs from the search index.
    .map(rug => ({ ...rug, collectionSlug: rug.collectionSlug || 'onlyUsedForSearchRugs', isNew: isRugNew(rug.created_at) }));

  const newRugs = filteredMappedRugs
    .filter(rug => rug.isNew)
    .slice()
    .sort((a, b) => compareDesc(parseISO(a.created_at), parseISO(b.created_at)));

  const oldRugs = filteredMappedRugs.filter(rug => !rug.isNew).sort((a, b) => a.name.localeCompare(b.name));

  const sortedRugs = [...newRugs, ...oldRugs];

  const sortedRugsWithPreOrPostExtraRugs = sortedRugs.flatMap(rug => {
    if (idx(rug, _ => _.collection_jk.collectionPage.slug) !== 'spectrum') {
      return rug;
    }

    const foundPreOrPostExtraRugs = rugsWithPreOrPostExtra.filter(preOrPostExtraRug => preOrPostExtraRug.name === rug.name);

    if (foundPreOrPostExtraRugs.length === 0) {
      return rug;
    }

    // This is a lot of custom code for one collection.
    const spectrumSurpreme = foundPreOrPostExtraRugs.filter(preOrPostExtraRug => preOrPostExtraRug.postExtra === 'Polonaise Redbridge');
    const mauroSpectrumSurpreme = foundPreOrPostExtraRugs.filter(preOrPostExtraRug => preOrPostExtraRug.preExtra === 'Frame');
    const spectrumBasics = foundPreOrPostExtraRugs.filter(preOrPostExtraRug => {
      if (!notNullOrUndefined(preOrPostExtraRug.postExtra)) {
        return false;
      }

      return preOrPostExtraRug.postExtra.includes('Basic');
    });

    return [rug, ...spectrumSurpreme, ...mauroSpectrumSurpreme, ...spectrumBasics];
  });

  const primaryRug = sortedRugsWithPreOrPostExtraRugs.find(rug => rug.isPrimary);
  const unPrimaryRugs = sortedRugsWithPreOrPostExtraRugs.filter(rug => !rug.isPrimary);

  const finalRugs = [primaryRug, ...unPrimaryRugs].filter(notNullOrUndefined);

  return finalRugs;
};
